import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Link from '@rsa-digital/evo-shared-components/components/Link';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';

/* desktop sizes */
const BANNER_HEIGHT = '408px';
const BANNER_HEIGHT_TABLET = '313px';
const BANNER_HEIGHT_MOBILE = '235px';
const BIG_TRIANGLE_WIDTH = '143vw';
const SMALL_TRIANGLE_HEIGHT = '285px';
const SMALL_TRIANGLE_WIDTH = '36vw';

/* tablet sizes */
const BANNER_WIDTH_TABLET = '100vw';
const TRAPEZOID_HEIGHT_TABLET = '133px';
const BIG_TRIANGLE_HEIGHT_TABLET = '180px';
const SMALL_TRIANGLE_HEIGHT_TABLET = '275px';
const SMALL_TRIANGLE_WIDTH_TABLET = '50vw';

/* mobile sizes */
const BANNER_WIDTH_MOBILE = '100vw';
const TRAPEZOID_HEIGHT_MOBILE = '143px';
const BIG_TRIANGLE_HEIGHT_MOBILE = '92px';
const SMALL_TRIANGLE_HEIGHT_MOBILE = '190px';
const SMALL_TRIANGLE_WIDTH_MOBILE = '100vw';

const bigTriangleStyles = css`
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  height: ${TRAPEZOID_HEIGHT_MOBILE}; // height of 'square' part
  width: 0;
  z-index: -1;

  border-left: ${BANNER_WIDTH_MOBILE} solid ${colors.core01}; // width of banner
  border-bottom: ${BIG_TRIANGLE_HEIGHT_MOBILE} solid transparent; // height of triangle tip

  ${mediaQuery.tabletPortrait`
    height: ${TRAPEZOID_HEIGHT_TABLET};
    border-left: ${BANNER_WIDTH_TABLET} solid ${colors.core01};
    border-bottom: ${BIG_TRIANGLE_HEIGHT_TABLET} solid transparent;
  `}

  ${mediaQuery.tabletLandscape`
    border: 0;
    height: auto;
    border-top: ${BANNER_HEIGHT} solid ${colors.core01};
    border-right: ${BIG_TRIANGLE_WIDTH} solid transparent;
  `}
`;

const smallTriangleStyles = css`
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  width: 0;
  z-index: -2;

  border-top: ${SMALL_TRIANGLE_HEIGHT_MOBILE} solid ${colors.core02};
  border-left: ${SMALL_TRIANGLE_WIDTH_MOBILE} solid transparent;

  ${mediaQuery.tabletPortrait`
    top: 0;
    border-top: ${SMALL_TRIANGLE_HEIGHT_TABLET} solid ${colors.core02};
    border-left: ${SMALL_TRIANGLE_WIDTH_TABLET} solid transparent;
  `}

  ${mediaQuery.tabletLandscape`
    border: 0;
    border-right: ${SMALL_TRIANGLE_WIDTH} solid ${colors.core02};
    border-bottom: ${SMALL_TRIANGLE_HEIGHT} solid transparent;
  `}
`;

export const OrigamiBackground = styled.div`
  position: relative;
  overflow: hidden;

  /* takes background to full screen */
  margin: 0 calc(50% - 50vw) ${spacing(4)};
  /* resets the content back to the grid */
  padding: 0 calc(50vw - 50%);

  height: ${BANNER_HEIGHT_MOBILE};

  ${mediaQuery.tabletPortrait`
      height: ${BANNER_HEIGHT_TABLET};
  `}

  ${mediaQuery.tabletLandscape`
      height: ${BANNER_HEIGHT};
      margin-bottom: 0;
  `}

  /* Large triangle on the left */
  &::before {
    ${bigTriangleStyles}
  }

  /* Smaller triangle on the right */
  &::after {
    ${smallTriangleStyles}
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${spacing(1)};
  align-items: center;
`;

export const StyledGridItem = styled(GridItem)`
  ${mediaQuery.tabletLandscape`
    display: none;
  `}
`;

export const StyledButtonLink = styled(Link)`
  ${fonts.paragraphLarge}
  && {
    color: ${colors.neutral08};
    font-weight: normal;
  }

  display: block;
  margin-top: ${spacing(4)};
  text-decoration: underline;

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(6)};
  `}

  &:hover,
  &:focus {
    color: ${colors.neutral08};
    text-decoration: none;
  }
  &:focus {
    &:after {
      box-shadow: 0px 0px 0 2px ${colors.neutral08};
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
