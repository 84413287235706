import sha256 from 'crypto-js/sha256';
import isMobile from 'is-mobile';
import { useEffect } from 'react';
import {
  PolicyAccordionBlock,
  PolicyDocumentsProps,
} from 'components/blocks/PolicyAccordion/types';
import { CsBlock } from './blockMapper';
import { trackEvent } from './eventTracking';

export type AnalyticsPageVariables = {
  pageCategories: string[];
  pageType: string;
  pageError?: number;
  userId?: string;
  policyExpiry?: string;
  policyTimeRemaining?: string;
  numberOfDrivers?: number | undefined;
  openDriving?: string;
  businessUser?: string;
};

export const useAnalyticsAccountPageVariables = (
  analyticsPageVariables: AnalyticsPageVariables,
  blocks: CsBlock[]
): void => {
  const documentBlocks = blocks
    .filter((block) => block.policy_accordion?.accordion_entries)
    .flatMap((block) => block.policy_accordion.accordion_entries as PolicyAccordionBlock)
    .filter((block) => block.policy_documents);
  const csDocuments = documentBlocks.flatMap((block) =>
    (block as PolicyDocumentsProps).policy_documents.cs_documents.map(
      (document) => document.heading
    )
  );
  const apiDocuments = documentBlocks.map(
    (block) => (block as PolicyDocumentsProps).policy_documents.api_document.heading
  );

  const userId = analyticsPageVariables.userId
    ? sha256(analyticsPageVariables.userId).toString()
    : undefined;

  useEffect(
    () => {
      if (userId)
        trackEvent({
          event: 'pageVars',
          pageCategories: analyticsPageVariables.pageCategories,
          pageType: analyticsPageVariables.pageType,
          pageError: analyticsPageVariables.pageError,
          isMobile: isMobile({ featureDetect: true }),
          downloadDocuments: [...apiDocuments, ...csDocuments],
          userId,
          policyExpiry: analyticsPageVariables.policyExpiry,
          policyTimeRemaining: analyticsPageVariables.policyTimeRemaining,
          numberOfDrivers: analyticsPageVariables.numberOfDrivers,
          openDriving: analyticsPageVariables.openDriving,
          businessUser: analyticsPageVariables.businessUser,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analyticsPageVariables.policyExpiry, analyticsPageVariables.policyTimeRemaining, userId]
  );
};
