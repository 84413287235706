import { PolicyDocumentApiResponse } from './types';
import apiClient from '../apiClient';

export type PolicyDocumentsClient = {
  getPolicyDocuments: (policyNumber: string) => Promise<PolicyDocumentApiResponse[]>;
};

const policyDocumentsClient: PolicyDocumentsClient = {
  getPolicyDocuments: (policyNumber: string) => apiClient.get(`policy/${policyNumber}/documents`),
};

export default policyDocumentsClient;
