import policyDetailsService from 'apiHelpers/policyDetails/service';
import policyDocumentsService from 'apiHelpers/policyDocument/service';
import React, { useEffect, useState } from 'react';
import { useUpdateDocuments } from 'state/document/actions';
import { useUpdatePolicy } from 'state/policy/actions';
import { PolicyAndCoverNotes, usePolicyState } from 'state/policy/state';
import { SessionState, useUserState } from 'state/user/state';
import useRequestHandler from 'helpers/useRequestHandler';

type LoadPolicyDetailsWrapperProps = {
  children: React.ReactNode;
  loadDocuments?: boolean;
};

const LoadPolicyDetailsWrapper: React.FC<LoadPolicyDetailsWrapperProps> = ({
  children,
  loadDocuments,
}) => {
  const requestHandler = useRequestHandler();
  const updatePolicyState = useUpdatePolicy();
  const updatePolicyDocuments = useUpdateDocuments();
  const policyState = usePolicyState();
  const [policyLoaded, setPolicyLoaded] = useState<boolean>(false);
  const userSession = useUserState();
  const policyInRedux = !!policyState.policy;
  const requiresReload = !!policyState?.requiresReload;

  useEffect(() => {
    const sessionLoaded = userSession.sessionState === SessionState.Loaded;
    if (sessionLoaded && (!policyLoaded || requiresReload)) {
      const useLoadingSpinner = !policyInRedux;
      requestHandler(
        () =>
          policyDetailsService
            .getPolicyDetails(userSession.crn)
            .then((policyAndCoverNotes: PolicyAndCoverNotes) =>
              updatePolicyState({ ...policyAndCoverNotes, requiresReload: false })
            ),
        useLoadingSpinner
      );
      if (loadDocuments) {
        requestHandler(
          () =>
            policyDocumentsService
              .getPolicyDocuments(userSession.crn)
              .then(updatePolicyDocuments)
              .catch((e) => {
                updatePolicyDocuments(null);
                console.error(`Failed to get policy document: ${e.message}`);
              }),
          false
        );
      }
      setPolicyLoaded(true);
    }
  }, [
    loadDocuments,
    policyInRedux,
    policyLoaded,
    requiresReload,
    requestHandler,
    updatePolicyDocuments,
    updatePolicyState,
    userSession,
  ]);

  return <>{children}</>;
};

export default LoadPolicyDetailsWrapper;
