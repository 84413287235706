import { Policy } from 'state/policy/state';
import { CsAccountAreaPage } from 'templates/AccountAreaPage';
import { CsPlaceholders, replacePlaceholdersPlainText } from './replaceCsPlaceholders';

const accountPlaceholders: CsPlaceholders<Policy> = {
  firstName: {
    getSubstitutionText: (policy: Policy): string => policy?.personalDetails.firstName ?? '',
    isPii: true,
  }, // TODO: add temporary/permanent driver replacement text
};

const replaceAccountPlaceholders = (
  policy: Policy,
  csAccountAreaPage: CsAccountAreaPage
): CsAccountAreaPage => {
  const replacePlainTextAccountPlaceholders = replacePlaceholdersPlainText(
    accountPlaceholders,
    policy,
    true // The Account hero is wrapped in a `div` with `data-pii-mask="true"`
  );
  return {
    ...csAccountAreaPage,
    hero: {
      ...csAccountAreaPage.hero,
      heading: replacePlainTextAccountPlaceholders(csAccountAreaPage.hero.heading),
    },
  };
};

export default replaceAccountPlaceholders;
