import { getDifferenceInMonths } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { usePolicyState } from 'state/policy/state';
import ErrorBoundary from 'components/ErrorBoundary';
import ReduxErrorBoundary from 'components/ErrorBoundary/ReduxErrorBoundary';
import ShortOrigamiHero, { ShortHeroData } from 'components/Hero/ShortOrigamiHero';
import Layout from 'components/Layout';
import LoadPolicyDetailsWrapper from 'components/LoadPolicyDetailsWrapper';
import SessionExpiryWrapper from 'components/SessionExpiryWrapper';
import StaffRedirectionWrapper from 'components/StaffRedirectionWrapper';
import UserSessionWrapper from 'components/UserSessionWrapper';
import { CsBlock, mapBlocks } from 'helpers/blockMapper';
import { processPageMeta } from 'helpers/csTypeProcessors';
import replaceAccountPlaceholders from 'helpers/placeholders/accountPlaceholders';
import { useAnalyticsAccountPageVariables } from 'helpers/useAnalyticsAccountPageVariables';
import { PageMeta } from 'types/contentStack';

type AccountAreaPageContentProps = {
  data: AccountAreaPageData;
};

const AccountAreaPageContent: React.FC<AccountAreaPageContentProps> = ({ data }) => {
  const { policy } = usePolicyState();
  const page = replaceAccountPlaceholders(policy, data.csAccountAreaPage);

  const { meta, breadcrumbs } = processPageMeta(page.page_meta, page.url);
  const jsonLdIsEmpty = page.json_ld === '' || page.json_ld === '{}';

  const analyticsVariables = {
    pageCategories: breadcrumbs.parent_links.map((link) => link.page_name),
    pageType: 'AccountAreaPage',
    userId: policy?.policyDetails.policyNumber ?? undefined,
    policyExpiry: policy?.policyDetails.renewalDate
      ? policy.policyDetails.renewalDate.toUTCString()
      : undefined,
    policyTimeRemaining: policy?.policyDetails.renewalDate
      ? getDifferenceInMonths(new Date(), policy.policyDetails.renewalDate).toString()
      : undefined,
    ...(page.page_id === 'account-area-policy'
      ? {
          numberOfDrivers: policy?.namedDrivers.length,
          openDriving: policy?.policyDetails.openDriving ? 'yes' : 'no',
          businessUser: policy?.namedDrivers.find((driver) => driver.businessUse) ? 'yes' : 'no',
        }
      : {}),
  };
  useAnalyticsAccountPageVariables(analyticsVariables, page.blocks);

  return (
    <>
      {!jsonLdIsEmpty && <Helmet script={[helmetJsonLdProp(JSON.parse(page.json_ld))]} />}
      <Layout
        pageType="accountArea"
        meta={meta}
        breadcrumbs={breadcrumbs}
        complianceText={page.footer_compliance ?? undefined}
        showStickyHeader={page.show_sticky_header ?? undefined}
        showAlertNotification={page.show_alert_notification ?? undefined}>
        <div data-pii-mask="true">
          <ShortOrigamiHero hero={page.hero} />
        </div>
        {page.blocks && mapBlocks(page.blocks)}
      </Layout>
    </>
  );
};

type AccountAreaPageProps = AccountAreaPageContentProps & {
  location: Location;
};

const AccountAreaPage: React.FC<AccountAreaPageProps> = ({ location, data }) => (
  <ErrorBoundary location={location} pageType="accountArea">
    <ReduxErrorBoundary location={location} pageType="accountArea">
      <UserSessionWrapper>
        <StaffRedirectionWrapper>
          <SessionExpiryWrapper>
            <LoadPolicyDetailsWrapper loadDocuments>
              <AccountAreaPageContent data={data} />
            </LoadPolicyDetailsWrapper>
          </SessionExpiryWrapper>
        </StaffRedirectionWrapper>
      </UserSessionWrapper>
    </ReduxErrorBoundary>
  </ErrorBoundary>
);

export default AccountAreaPage;

export type CsAccountAreaPage = {
  title: string;
  url: string;
  page_id: string;
  hero: ShortHeroData;
  blocks: CsBlock[];
  page_meta: PageMeta;
  json_ld: string;
  footer_compliance: string | null;
  show_sticky_header: boolean | null;
  show_alert_notification: boolean | null;
};

type AccountAreaPageData = {
  csAccountAreaPage: CsAccountAreaPage;
};

export const query = graphql`
  query($id: String!) {
    csAccountAreaPage(id: { eq: $id }) {
      title
      url
      page_id
      ...ShortHeroAccountArea
      footer_compliance
      show_sticky_header
      show_alert_notification
      blocks {
        ...RichTextBlockAccountArea
        ...BasicAccountAccordionBlockAccountArea
        ...PolicyCardBlockAccountArea
        ...PolicyAccordionBlockAccountArea
        ...CookiesListBlockAccountArea
      }
      page_meta {
        ...BreadcrumbsAccountArea
        ...MetaDataTagsAccountArea
      }
      json_ld
    }
  }
`;
