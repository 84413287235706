import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import { SessionState, useUserState } from 'state/user/state';

type StaffAreaUrl = {
  csStaffAreaPage: {
    url: string;
  };
};

export const query = graphql`
  query {
    csStaffAreaPage(page_id: { eq: "staff-portal" }) {
      url
    }
  }
`;

const StaffRedirectionWrapper: React.FC = ({ children }) => {
  const userState = useUserState();
  const { url: staffAreaUrl } = useStaticQuery<StaffAreaUrl>(query).csStaffAreaPage;

  const isStaffMember = userState.sessionState === SessionState.Loaded && userState.isStaffMember;

  useEffect(() => {
    if (isStaffMember) {
      navigate(staffAreaUrl, { replace: true });
    }
  }, [staffAreaUrl, isStaffMember]);

  if (isStaffMember) {
    return null;
  }

  return <>{children}</>;
};

export default StaffRedirectionWrapper;
