import { DocumentsState } from 'state/document/state';
import parsePolicyDocumentsApiResponse from 'helpers/apiResponseParsers/policyDocumentsParser';
import policyDocumentsClient from './client';

export type PolicyDocumentsService = {
  getPolicyDocuments: (policyNumber: string) => Promise<DocumentsState>;
};

const policyDocumentsService: PolicyDocumentsService = {
  getPolicyDocuments: (policyNumber: string) =>
    policyDocumentsClient.getPolicyDocuments(policyNumber).then(parsePolicyDocumentsApiResponse),
};

export default policyDocumentsService;
