import { parseOptionalDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { PolicyDocumentApiResponse } from 'apiHelpers/policyDocument/types';
import { DocumentsState } from 'state/document/state';
import { compareDatesInDescendingOrder } from 'helpers/dateHelpers';

const parsePolicyDocumentsApiResponse = (
  policyDocumentsApiResponse: PolicyDocumentApiResponse[]
): DocumentsState => {
  if (policyDocumentsApiResponse.length === 0) {
    return [];
  }

  const parsedPolicyDocs = policyDocumentsApiResponse.map((policyDocumentApiResponse) => ({
    id: policyDocumentApiResponse.id,
    documentDate: parseOptionalDate(policyDocumentApiResponse.documentDate),
  }));

  return [...parsedPolicyDocs].sort((x, y) =>
    compareDatesInDescendingOrder(x.documentDate, y.documentDate)
  );
};

export default parsePolicyDocumentsApiResponse;
