import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import {
  Heading,
  Subhead,
} from '@rsa-digital/evo-shared-components/components/Hero/HeroWithOrigamiBackground/styles';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { applyComponentTheme } from '@rsa-digital/evo-shared-components/theme';
import { graphql } from 'gatsby';
import React from 'react';
import { handleAndTrackLinkClick } from 'helpers/navigation';
import {
  ButtonWrapper,
  IconWrapper,
  OrigamiBackground,
  StyledButtonLink,
  StyledGridItem,
} from './styles';

export type ShortHeroWithOrigamiBackgroundProps = {
  hero: ShortHeroData;
} & ComponentProps;

export type ShortHeroData = {
  heading: string;
  subhead?: string;
  back_button: { display_text?: string; url?: string };
};

export const query = graphql`
  fragment ShortHeroAccountArea on cs__account_area_page {
    hero {
      heading
      subhead
      back_button {
        display_text
        url
      }
    }
  }
`;

const ShortHeroWithOrigamiBackground: React.FC<ShortHeroWithOrigamiBackgroundProps> = ({
  hero,
  ...props
}) => (
  <OrigamiBackground data-cy="ShortHeroOrigamiBackground">
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Grid alignLeft {...componentProps(props)}>
      {hero.back_button.display_text && hero.back_button.url && (
        <StyledGridItem desktop={10} tabletLandscape={10}>
          <StyledButtonLink
            onClick={handleAndTrackLinkClick(hero.back_button.url, 'backButton', hero.heading)}
            href={hero.back_button.url}>
            <ButtonWrapper>
              <IconWrapper>
                <Icon name="chevron" size="small" flipX color={colors.neutral08} />
              </IconWrapper>
              {hero.back_button.display_text}
            </ButtonWrapper>
          </StyledButtonLink>
        </StyledGridItem>
      )}
      <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
        <Heading backButton={!!(hero.back_button.display_text && hero.back_button.url)}>
          {hero.heading}
        </Heading>
        {hero.subhead && <Subhead>{hero.subhead}</Subhead>}
      </GridItem>
    </Grid>
  </OrigamiBackground>
);

export default applyComponentTheme(
  ShortHeroWithOrigamiBackground,
  'ShortHeroWithOrigamiBackground'
);
